<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as CONTACT_TYPES } from '../../../organization/contact/contact/owner-contact/store'
import { vue } from '../../../../main'

export default {
  name: 'PerformerMixin',
  created () {
    this.setContactList({})
  },
  computed: {
    ...mapGetters({
      contactList: CONTACT_TYPES.GENERIC.organization.ownercontact.LIST.GETTERS.list
    }),
    performerOptions () {
      const options = []
      if (this.contactList && this.contactList.results && this.contactList.results.length > 0) {
        this.contactList.results.forEach((instance) => {
          const option = {
            status: instance.status,
            id: instance.id,
            name: `${instance.first_name} ${instance.last_name}`
          }
          if (instance.status === 0) {
            option.$isDisabled = true
          }
          options.push(option)
        })
      }
      return options
    }
  },
  methods: {
    ...mapMutations({
      setContactList: CONTACT_TYPES.GENERIC.organization.ownercontact.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      getContactList: CONTACT_TYPES.GENERIC.organization.ownercontact.LIST.ACTIONS.setFilters
    }),
    onSearchPerformer (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.filter__last_name__icontains = search
        filters.order_by = '-status.-date_create'
        this.getContactList(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setContactList({})
        vue.$store.commit('componentLoading', true)
      }
    },
    selectPerformer (item) {
      this.$set(this.form, 'performer', item.id)
      this.$set(this.form, 'performer_name', item.name)
    },
    clearPerformer () {
      this.$set(this.form, 'performer', null)
      this.$set(this.form, 'performer_name', null)
    }
  }
}
</script>
