<template>
  <div>
    <panel :title="getTitle" :no-button="true" v-has-perms="rolePerm">
      <thux-list-component
        :fields="getFields"
        :pagination="getPaginationFields"
        @select-per-page="changePerPage"
        @change-page="changePage"
        :action-select="getActionSelectFields"
        @select-action="setAction"
        @select-or-deselect-all-queryset="selectOrDeselectAllQueryset"
        @do-action="doAction($event, fieldName)"
        :advanced-search="getAdvancedSearchFields"
        @show-advanced-search="openAdvancedSearch"
        @open-edit-form="openEditForm()"
        @open-detail-form="openDetailForm()"
      >
        <template slot="other-headers-buttons">
          <b-btn
            variant="outline-primary"
            :title="getButtonTitle"
            class="ml-2"
            @click="cancel"
          >
            <i class="fas fa-times"></i>
            {{ getButtonTitle | translate }}
          </b-btn>
          <b-btn
            variant="outline-primary"
            v-has-perms="createAttachmentRolePerm"
            :title="'Upload attachments' | translate"
            class="ml-2"
            @click="showModal"
          >
            <i class="fas fa-upload"></i>
            {{ 'Upload' | translate }}
          </b-btn>
        </template>
        <template slot="body">
          <component-table
            v-if="Array.isArray(list)"
            :list="Array.isArray(list) ? list : []"
            :goBack="goBack"
            :params="tableParams"
            :resetForm="resetForm"
            @open-edit-form="openEditForm"
            @open-detail-form="openDetailForm"
            @open-light-box="openLightBox"
            @update-actions="updateActions"
            @update-media="setMedia(Object.values($event))"
          />
        </template>
      </thux-list-component>
    </panel>
    <LightBox
      v-if="showLightBox"
      :media="media"
      :startAt="startAt"
      @onClosed="showLightBox = false"
    />
    <ConfirmDialog
      v-if="showAddAttachments"
      ref-id="modal-delete"
      :no-close-on-backdrop="true"
      content-class="attachment-modal"
      confirm-btn-variant="primary"
      :title="$t('Add attachments')"
      :button-confirm-text="'Save' | translate"
      @confirm-dialog-close="closeModal"
      @confirm-dialog-select="onSubmit"
    >
      <template slot="body">
        <thux-vue-file-agent @update-attachments="updateAttachments"/>
      </template>
    </ConfirmDialog>
    <ConfirmDialog
      v-if="showDeleteAttachments"
      ref-id="modal-delete"
      :title="$t('Are you sure to remove this attachments?')"
      :button-confirm-text="'Remove' | translate"
      @confirm-dialog-close="closeModal"
      @confirm-dialog-select="deleteAttachments"
    >
        <template slot="body">
          <b-table
            no-local-sorting
            show-empty
            striped
            hover
            responsive
            :items="selectedList"
            :fields="attachmentFields"
          >
            <template slot="cell(image)" slot-scope="row">
              <img
                v-if="row.item.attachment_base64"
                height="64px" width="64px"
                :src="`data:image/png;base64,${row.item.attachment_base64}`"
                class="media-object" alt=""
              />
              <div
                v-else
                :title="$t('Preview not available')"
                class="attachment-preview-generic clickable"
              >
                <i class="fa fa-file-alt"></i>
              </div>
            </template>
            <template slot="cell(name)" slot-scope="row">
              <span>{{ row.item.name }}</span>
            </template>
            <template slot="cell(attachment)" slot-scope="row">
              <span>{{ row.item.simple_file_name }}</span>
            </template>
          </b-table>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as YARD_INSPECTION_ATTACHMENTS_TYPES } from '../../../yard-inspection-attachment/store'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../../../components/thux-list/ThuxListMixin'
import YardInspectionAttachmentsTable from './YardInspectionAttachmentsTable'
import { cloneDeep } from 'lodash'
import ConfirmDialog from '../../../../../components/ConfirmDialog'
import ThuxVueFileAgent from '../../../../../components/ThuxVueFileAgent'
import { storeMixin } from '../../../../../storeMixin'

import LightBox from 'vue-it-bigger'
import { TYPES as YARD_INSPECTION_TYPES } from '../../../yard-inspection/store'
import('vue-it-bigger/dist/vue-it-bigger.min.css') // when using webpack

export default {
  name: 'YardInspectionAttachmentsList',
  props: {
    inspection: { required: true },
    inspectionNum: { required: true }
  },
  mixins: [ThuxListMixin],
  components: {
    'component-table': YardInspectionAttachmentsTable,
    ConfirmDialog,
    ThuxVueFileAgent,
    LightBox
  },
  watch: {
    inspection () {
      this.getComponentList()
    }
  },
  data () {
    return {
      formIsChanged: false,
      showLightBox: false,
      showAddAttachments: false,
      showDeleteAttachments: false,
      showHeader: false,
      showPagination: false,
      showFooterPagination: false,
      showCommonFilters: false,
      resetForm: false,
      initialList: [],
      attachments: [],
      media: [],
      startAt: 0,
      attachmentsImages: {},
      actionOption: { value: 'save_selected', text: this.$t('Save selected') },
      actions: [
        { value: 'save_selected', text: this.$t('Save selected'), permissions: 'yard_yardinspectionattachment_update' },
        { value: 'download', text: this.$t('Download selected'), permissions: 'yard_yardinspectionattachment_download' },
        { value: 'delete', text: this.$t('Delete selected'), permissions: 'yard_yardinspectionattachment_destroy' }
      ].filter(element => {
        return hasPerm(element.permissions)
      }),
      attachmentFields: [
        {
          key: 'image',
          label: this.$t('Preview'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
        {
          key: 'name',
          label: this.$t('Name'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap'
        },
        {
          key: 'attachment',
          label: this.$t('File'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap'
        }
      ],
      // Permissions
      rolePerm: ['yard_yardinspectionattachment_list'],
      actionEnablePermission: ['yard_yardinspectionattachment_enable'],
      actionDisablePermission: ['yard_yardinspectionattachment_disable'],
      createAttachmentRolePerm: ['yard_yardinspectionattachment_create']
    }
  },
  created () {
    this.$set(this, 'attachments', [])
    this.updateActions(false)
  },
  computed: {
    ...mapGetters({
      ...YARD_INSPECTION_ATTACHMENTS_TYPES.GENERIC.yard.yardinspectionattachment.LIST.GETTERS
    }),
    getTitle () {
      if (this.inspectionNum) {
        return `${this.$t('Attachments')} ${this.$t('inspection')} n°${this.inspectionNum}`
      }
      return `${this.$t('Attachments')} ${this.$t('inspection')}`
    },
    getButtonTitle () {
      if (this.formIsChanged) {
        return this.$t('Cancel')
      }
      return this.$t('Close')
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return false
    }
  },
  methods: {
    ...mapActions({
      ...YARD_INSPECTION_ATTACHMENTS_TYPES.GENERIC.yard.yardinspectionattachment.LIST.ACTIONS,
      create: YARD_INSPECTION_ATTACHMENTS_TYPES.GENERIC.yard.yardinspectionattachment.DETAIL.ACTIONS.create,
      partialUpdate: YARD_INSPECTION_ATTACHMENTS_TYPES.GENERIC.yard.yardinspectionattachment.DETAIL.ACTIONS.partialUpdate,
      delete: YARD_INSPECTION_ATTACHMENTS_TYPES.GENERIC.yard.yardinspectionattachment.DETAIL.ACTIONS.delete,
      download: YARD_INSPECTION_ATTACHMENTS_TYPES.GENERIC.yard.yardinspectionattachment.DETAIL.ACTIONS.download,
      getYardInspectionList: YARD_INSPECTION_TYPES.GENERIC.yard.yardinspection.LIST.ACTIONS.getList
    }),
    ...mapMutations({
      setSelectedList: YARD_INSPECTION_ATTACHMENTS_TYPES.GENERIC.yard.yardinspectionattachment.LIST.MUTATIONS.setSelectedList,
      setSelectAll: YARD_INSPECTION_ATTACHMENTS_TYPES.GENERIC.yard.yardinspectionattachment.LIST.MUTATIONS.setSelectAll,
      setList: YARD_INSPECTION_ATTACHMENTS_TYPES.GENERIC.yard.yardinspectionattachment.LIST.MUTATIONS.setSelectAll
    }),
    updateActions (disableDownload) {
      this.$set(this, 'formIsChanged', disableDownload)
      if (disableDownload) {
        this.$set(this, 'actions', [
          { value: 'save_selected', text: this.$t('Save selected'), permissions: 'yard_yardinspectionattachment_update' },
          { value: 'delete', text: this.$t('Delete selected'), permissions: 'yard_yardinspectionattachment_destroy' }
        ].filter(element => {
          return hasPerm(element.permissions)
        }))
      } else {
        this.$set(this, 'actions', [
          { value: 'save_selected', text: this.$t('Save selected'), permissions: 'yard_yardinspectionattachment_update' },
          { value: 'download', text: this.$t('Download selected'), permissions: 'yard_yardinspectionattachment_download' },
          { value: 'delete', text: this.$t('Delete selected'), permissions: 'yard_yardinspectionattachment_destroy' }
        ].filter(element => {
          return hasPerm(element.permissions)
        }))
      }
      this.$set(this, 'actionOption', this.actions?.[0] ? { value: null, text: '---' } : this.actions[0])
    },
    downloadOrUpdateAttachment (instance, actionType) {
      let attachment = {
        id: instance.id,
        name: instance.name,
        attachment: instance.attachment,
        inspection: this.inspection,
        simple_file_name: instance.simple_file_name
      }
      if (actionType === 'download') {
        const list = attachment.simple_file_name.split('.')
        const ext = list[list.length - 1]
        return this.download({
          id: attachment.id,
          action: 'download',
          simpleFileName: `${attachment.name}.${ext}`
        })
      } else {
        if (!attachment.name) {
          attachment.name = attachment.attachment.name
        }
        attachment = storeMixin.utils.parseFormMultipart(attachment, ['attachment'])
        return this.partialUpdate(attachment)
      }
    },
    deleteAttachments () {
      if (this.selectedList.length !== 0) {
        const promises = []
        this.selectedList.forEach((instance) => {
          const attachment = {
            id: instance.id,
            name: instance.name,
            attachment: instance.attachment,
            inspection: this.inspection,
            simple_file_name: instance.simple_file_name
          }
          promises.push(this.delete(attachment))
        })
        Promise.all(promises).then(() => {
          this.closeModal()
          this.getYardInspectionList()
          this.getComponentList()
        })
      }
    },
    doAction (actionType, fieldName = 'id') {
      if ((this.selectedList.length !== 0 || this.selectAll) && actionType) {
        const promises = []
        if (actionType === 'save_selected' || actionType === 'download') {
          this.selectedList.forEach((instance) => {
            promises.push(this.downloadOrUpdateAttachment(instance, actionType))
          })
        } else if (actionType === 'delete') {
          this.$set(this, 'showDeleteAttachments', true)
          return
        }
        Promise.all(promises).then(() => {
          this.getYardInspectionList()
          this.getComponentList()
        })
      }
    },
    showModal () {
      this.$set(this, 'attachments', [])
      this.$set(this, 'showAddAttachments', true)
    },
    closeModal () {
      this.$set(this, 'attachments', [])
      this.$set(this, 'showAddAttachments', false)
      this.$set(this, 'showDeleteAttachments', false)
    },
    updateAttachments (attachments) {
      if (!attachments) {
        attachments = []
      }
      this.$set(this, 'attachments', attachments)
    },
    cancel () {
      if (this.formIsChanged) {
        this.setList(this.initialList)
        this.getYardInspectionList()
        this.setSelectedList([])
        this.setSelectAll(false)
        this.$set(this, 'resetForm', !this.resetForm)
      } else {
        this.$emit('hide')
      }
    },
    onSubmit () {
      if (this.attachments.length > 0) {
        const promises = []
        this.attachments.forEach((attachment) => {
          attachment.inspection = this.inspection
          promises.push(this.create(attachment))
        })
        Promise.all(promises).then(() => {
          this.closeModal()
          this.getYardInspectionList()
          this.getComponentList()
        })
      } else {
        this.closeModal()
      }
    },
    openLightBox (attachmentId) {
      this.$set(this, 'startAt', this.attachmentsImages[attachmentId] ? this.attachmentsImages[attachmentId] : 0)
      this.$set(this, 'showLightBox', true)
    },
    checkImageExtension (attachment) {
      return !!(
        attachment.endsWith('.jpg') ||
        attachment.endsWith('.png') ||
        attachment.endsWith('.gif') ||
        attachment.endsWith('.svg') ||
        attachment.endsWith('.bmp') ||
        attachment.endsWith('.jpeg') ||
        attachment.endsWith('.webp')
      )
    },
    setMedia (list = null) {
      const media = []
      this.attachmentsImages = {}
      list = list || this.list
      let indexOnlyImages = 0
      if (list && list.length > 0) {
        list.forEach((attachment) => {
          if (this.checkImageExtension(attachment.simple_file_name)) {
            const image = attachment.file ? attachment.file : `data:image/png;base64,${attachment.attachment_base64}`
            media.push({
              // For an image
              type: 'image', // Can be omitted for image
              thumb: image,
              src: image
            })
            this.attachmentsImages[attachment.id] = indexOnlyImages
            indexOnlyImages += 1
          }
        })
      }
      this.$set(this, 'media', media)
    },
    getComponentList () {
      this.setList({})
      this.setSelectedList([])
      this.$set(this, 'media', [])
      this.$set(this, 'attachmentsImages', {})
      this.setSelectAll(false)
      this.setFilters({ filter__inspection: this.inspection, no_page: 'no_page' }).then(() => {
        this.setMedia()
        this.$set(this, 'initialList', cloneDeep(this.list))
      })
    }
  }
}
</script>
