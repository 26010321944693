<template>
  <div>
    <portal-target
      v-show="showList"
      name="layout-main"
      class="layout-main panel-scroll-full"
    >
      <div class="panel-list panel-fixed">
        <div class="panel-header">
          <h1 class="page-header mr-2 d-flex align-items-center">
            {{ titleWithYardName | translate }}
          </h1>
          <ol class="breadcrumb pull-right">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'dashboard'}">
                {{"Dashboard"}}
              </router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link :to="{ name: YARD_ROUTES.YARD_LIST }">
                {{$t("Yards")}}
              </router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link :to="{ name: YARD_ROUTES.YARD_DETAIL, params: { id: $route.params.id } }">
                {{$t("Yard")}}
              </router-link>
            </li>
            <li class="breadcrumb-item">{{ title | translate }}</li>
          </ol>
        </div>
        <component-edit
          v-if="showEditForm && editComponent"
          :Inspections="list.results"
          :id="componentId"
          :yard="$route.params.id"
          :editParams="editParams"
          @on-submit="refreshListAfterSubmit"
          @close-form='closeEditForm'
        />
        <yard-inspection-attachments-list
          v-if="showAttachments"
          :inspection="inspection.id"
          :inspection-num="inspection.num"
          @hide="showAttachments = false"
        />

        <advanced-search
          v-show="showAdvancedSearch"
          v-if="toggleAdvancedSearch"
          :fields="searchFields"
          :reset="reset"
          @search="addFiltersToInstance"
          @reset="removeFiltersToInstance"
          @active="activeFilters = $event"
        />
        <panel :title="title" :no-button="true">
          <thux-list-component
            :fields="getFields"
            :pagination="getPaginationFields"
            @select-per-page="changePerPage"
            @change-page="changePage"
            :action-select="getActionSelectFields"
            @select-action="setAction"
            @select-or-deselect-all-queryset="selectOrDeselectAllQueryset"
            @do-action="doAction($event, fieldName)"
            :advanced-search="getAdvancedSearchFields"
            @show-advanced-search="openAdvancedSearch"
            @open-edit-form="openEditForm()"
            @open-detail-form="openDetailForm()"
          >
            <template slot="body">
              <component-table
                :list="list.results"
                :goBack="goBack"
                :params="tableParams"
                @open-edit-form="openEditForm"
                @open-detail-form="openDetailForm"
                @show-attachments="showYardInspectionAttachments"
                @refresh-list="refreshListAfterSubmit"
              />
            </template>
          </thux-list-component>
        </panel>
      </div>
    </portal-target>
    <router-view />
    <portal-target
      v-if="showCommonFilters"
      v-show="showList"
      name="layout-sidebar"
      class="layout-side panel-scroll-full"
    >
      <button
        @click.prevent="closeRightSidebar"
        class="btn btn-primary text-uppercase w-100 mb-4"
        :aria-label="$t('Close')"
      >
        <i class="fas fa-times" />
        {{ $t('Hide sidebar') }}
      </button>
      <common-filters />
    </portal-target>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { ROUTES as YARD_ROUTES } from '../router'
import { TYPES as YARD_TYPES } from '../store'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../../components/thux-list/ThuxListMixin'

import YardInspectionsTable from './YardInspectionsTable'
import YardTechnicianEdit from './YardInspectionEdit'
import { TYPES as YARD_INSPECTION_TYPES } from '../../yard-inspection/store'
import YardInspectionAttachmentsList from './attachment/YardInspectionAttachmentsList'

export default {
  name: 'YardInspectionsList',
  mixins: [ThuxListMixin],
  components: {
    YardInspectionAttachmentsList,
    'component-table': YardInspectionsTable,
    'component-edit': YardTechnicianEdit
  },
  data () {
    return {
      YARD_ROUTES,
      title: this.$t('Inspections'),
      rolePerm: ['yard_yardinspection_list'],
      actionEnablePermission: ['yard_yardinspection_enable'],
      actionDisablePermission: ['yard_yardinspection_disable'],
      inspection: {},
      showCommonFilters: false,
      showAttachments: false,
      searchFields: [
        { key: 'filter__number', placeholder: this.$t('Number'), type: 'number', col: 6 },
        { key: 'filter__date_inspection', placeholder: this.$t('Date inspection'), type: 'text', col: 6 },
        { key: 'filter__performer__first_name__icontains', placeholder: this.$t('Performer first name'), type: 'text', col: 6 },
        { key: 'filter__performer__last_name__icontains', placeholder: this.$t('Performer last name'), type: 'text', col: 6 }
      ]
    }
  },
  created () {
    this.yardRetrieve({ id: this.$route.params.id })
  },
  computed: {
    ...mapGetters({
      ...YARD_INSPECTION_TYPES.GENERIC.yard.yardinspection.LIST.GETTERS,
      yardDetail: YARD_TYPES.GENERIC.yard.yard.DETAIL.GETTERS.detail
    }),
    titleWithYardName () {
      if (this.yardDetail && this.yardDetail.code) {
        return `${this.$t('Inspections')} ${this.yardDetail.name ? this.yardDetail.name : this.yardDetail.customer_name} (${this.yardDetail.code})`
      }
      if (this.list && this.list.results && this.list.results.length > 0) {
        const yardName = this.list.results[0].yard_name ? `(${this.list.results[0].yard_name})` : ''
        return `${this.$t('Inspections')} ${yardName}`
      }
      return this.$t('Inspections')
    },
    showList () {
      return true
    },
    canSeeActionSelect () {
      return false
    },
    canAddInstance () {
      return hasPerm('yard_yardinspection_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: YARD_INSPECTION_TYPES.GENERIC.yard.yardinspection.LIST.MUTATIONS.setSelectedList,
      setSelectAll: YARD_INSPECTION_TYPES.GENERIC.yard.yardinspection.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...YARD_INSPECTION_TYPES.GENERIC.yard.yardinspection.LIST.ACTIONS,
      yardRetrieve: YARD_TYPES.GENERIC.yard.yard.DETAIL.ACTIONS.retrieve
    }),
    openEditForm (id) {
      this.$set(this, 'componentId', id)
      this.$set(this, 'inspection', { id: id })
      if (id) {
        this.$set(this, 'showEditForm', true)
        this.$set(this, 'showAttachments', true)
        this.$set(this, 'showDetailForm', false)
      } else {
        this.$set(this, 'showEditForm', true)
        this.$set(this, 'showAttachments', false)
        this.$set(this, 'showDetailForm', false)
      }
    },
    getComponentList () {
      this.cleanAllData()
      this.setFilters({ filter__yard_id: this.$route.params.id })
    },
    showYardInspectionAttachments (inspection) {
      this.$set(this, 'inspection', inspection)
      this.$set(this, 'showAttachments', true)
      if (this.componentId !== inspection.id) {
        this.$set(this, 'showEditForm', false)
        this.$set(this, 'showDetailForm', false)
      }
    },
    refreshListAfterSubmit () {
      this.getComponentList()
      this.closeEditForm()
    },
    closeEditForm () {
      this.$set(this, 'showDetailForm', false)
      this.$set(this, 'showEditForm', false)
      this.$set(this, 'showAttachments', false)
    }
  }
}
</script>
